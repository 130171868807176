
:root {
  --v-calendar-active-bg-color: var(--theme-primary-color);
  --v-calendar-datepicker-icon-color: var(--theme-primary-color);
  --v-calendar-range-bg-color: #edf0ff;
  --dynamic-width: 300px;
  --v-calendar-input-bg-disable-color: #f9f9f9;
}

.datepicker-wrap{
  flex-wrap: wrap;
  text-align: left;
  position: relative;
  z-index: 2;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: var(--dynamic-width);
  min-width: 300px;
  max-width: 100%;
  vertical-align: top;
  margin: 0;
}

  .vertical.datepicker-wrap {
    flex-wrap: nowrap;
    // background-color: red !important;
    .v-calendar.f-datepicker .content {
      flex-direction: column;
      transform: translate(-10%, -17%) scale(73%) !important;
    }
  }

.v-calendar.f-datepicker {
  display: inline-block;
  margin: 4px 2.5px;
}
.v-calendar .number {
  margin: -2px 0 0 -5px;
}
.v-calendar .button{
  border-radius: 0;
  margin: 0;
  line-height: 10px;
  font-size: 20px !important;
}
.v-calendar.f-datepicker .content {
  transform: translate(-11%, -20%) scale(73%);
}
.v-calendar.f-datepicker {
  display :inline-block;
  .input-field.long {
    min-width: 243px;
    input {
      color: #000;
      border-radius: var(--theme-input-radius);
      border-color: #ddd;
      height: 22px;
      padding: 1px 10px 1px 40px;
    }
    input:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    @keyframes shadow {
      to {
        box-shadow: 0 0 6px var(--theme-primary-color);
      }
    }
  }
}
.filter-days {
  display: inline-block;
  vertical-align: top;
  min-width: fit-content;
  //  margin: 4px 0;
  // margin: 0 4px;

  .button {
    // margin: 0;
  }
}
