
.datepicker-element-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
  &.isError {
    .el-range-editor {
      border-color: #e94d4d;
      &.is-active {
        border-color: #e94d4d;
        &:hover {
          border-color: #e94d4d;
        }
      }
    }
  }
}
/deep/ .el-range-editor {
  &.is-active {
    border-color: #ddd;
    border-color: var(--theme-primary-color);
    animation: shadow 0.1s ease-in-out forwards;
    &:hover {
    color:#fff;
      border-color: #ddd;
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
  }
  &.is-disabled {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    &.is-active {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
    }
    .el-range-separator {
      color: #cccccc;
    }
    input {
      background-color: #f2f2f2;
    }
    &:hover {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
    }
    &:focus {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
    }
  }
  .el-range__icon,
  .el-range-separator,
  .el-range__close-icon {
    line-height:1;
  }
  .el-range__close-icon {
    display:none!important;
  }
  .el-range-separator {
    padding: 0;
  }
  .el-range-input {
    font-weight: 500;
    line-height: 140%;
    width: 50%;
    font-size: 15px;
  }
}
.el-date-editor--daterange.el-input__inner,
.el-date-editor--monthrange.el-input__inner {
  width: auto;
  max-width: 280px;
}
/deep/ .el-date-editor {
  .el-range__icon {
    color:#3d5afe;
  }
}
/deep/ .el-date-editor {
  &.el-input {
    width:160px;
  }
  .el-input__suffix {
    display:none!important;
  }
  .el-input__inner {
    padding: 4px 0 4px 30px;
    font-size:14px;
    line-height:16px;
  }
}
/deep/ .el-input__inner {
  height: auto;
  margin:4px 0;
  padding:4px 10px;
  border-radius:5px;
  border:1px solid #ddd;
}
.filterDays {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0;
  /deep/ .gap {
    line-height:1;
  }
}
