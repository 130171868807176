
.badge {
    display: inline-block;
    margin: 1px;
    padding: 1px 3px;
    font-size: 12px;
    color: #fff;
    line-height: 14px;
    border-radius: 3px;
    background-color: red;
}
