

.icon-close {
  position:relative;
  top: 1px;
  right: -2px;
}
li {
  list-style-type: none;
}
.wrap-root {
  padding: 5px 0;
  .icon-close {
    float: right;
  }

}
.wrap-childs {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: no-wrap;
  padding: 5px 0;
  border-top: 1px solid #ddd;
}
@container multiselect (max-width: 400px) {
  .wrap-childs {
    flex-wrap: wrap;
  }
}
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}
.multiple-select {
  position: relative;
  left: 2.5px;
  display: inline-block;
  width: calc(100% - 5px);
  box-sizing: border-box;
  --item-height: 16px;
  line-height: var(--item-height);
  max-width: 100%;
  &:focus-within {
    z-index: 100;
  }
  text-align: left;
  // vertical-align: top;
  vertical-align: middle;
  container-name: multiselect;
  container-type: inline-size;
  user-select: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    color: graytext;
    .selected-items {
      background: #eee;
      &:focus-within, &:focus {
        border: 1px solid #bbb;
        animation: none;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 9px;
        width: 0;
        height: 0;
        margin-top: -2.5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #bbb;
      }
    }
    .item {
      background: #bbb !important;
    }
    .all-items {
      display: none;
      transform: scale(0) !important;
    }
  }
  .selected-items {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin: 4px 0;
    padding: 2px 9px 2px 16px;
    border: 1px solid #ddd;
    border-radius: var(--theme-input-radius);
    background: #fff;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 9px;
      width: 0;
      height: 0;
      margin-top: -2.5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--theme-primary-color);
    }
    &:focus-within, &:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    &:focus+.all-items, &+.all-items:hover {
      transform: scale(1);
    }
    .icon-close {
      float: right;
    }
  }
  .all-items {
    position: absolute;
    // z-index: 2000;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: var(--theme-input-radius);
    transform: scale(0);
    overflow: hidden;
    background: #fff;
    .wrap-childs {
      &:hover {
        box-shadow: inset 0px -10px 20px #8a8a8a22;
      }
    }
    &:focus-within {
      transform: scale(1);
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    .leafs-all {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis:200px;
      .icon-close {
        float: right;
      }
    }
    .leafs {
      display:flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0;
      .wrap-item {
        display: flex;
        box-sizing: border-box;
        width:200px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 200px;
      }
      .leaf-label {
        display: flex !important;
        overflow: hidden;
        min-width: 0;
        height: initial !important;
        // line-height: 16px;
        line-height: var(--item-height);
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .icon-close {
          flex-shrink: 0;
        }
      }
    }
  }
  .wrap-item {
    display: inline-block;
    margin: 0;
    padding-left: 9px;
    border: 0;
    .item {
      display: inline-block;
      box-sizing: border-box;
      margin: 1px 0;
      padding: 1px 4px;
      border: 0;
      border-radius: var(--theme-input-radius);
      font-weight: 500;
      word-break: break-all;
      // line-height: 16px;
      line-height: var(--item-height);
      text-indent: 0;
      cursor: pointer;
      ion-icon {
        visibility: hidden;
      }
    }
  }
  .selected {
    .item {
      color: #fff;
      background: var(--theme-primary-color);
      ion-icon {
        visibility: visible;
      }
    }
  }
  @keyframes shadow {
    to {
      box-shadow: 0 0 6px var(--theme-primary-color);
    }
  }
}
