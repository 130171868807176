
.infoBox {
  margin:0 6px;
  line-height:35px;
  color:#333333;
  font-size: 13px;
  font-weight: 600;
  .count {
    color: var(--theme-primary-color);
  }
}
