
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}
.wrap-input {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 5px);
  box-sizing: border-box;
}
.wrap-input:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 9px;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--theme-primary-color);

}
.wrap-picker {
  position: absolute;
  z-index: 10;
  width: 100%;
  min-width: 240px;
  padding:10px;
  outline: none;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: var(--theme-input-radius);
  background: #fff;
  transform: scale(0);
  display: flex;
  flex-direction: column;
  gap: 10px;
  .year {
    .handle {
      border: 1px solid #ddd;
      border-radius: var(--theme-input-radius);
      color: black;
      width: 40px;
      background: #fff;
      cursor: pointer;
    }
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    .picked {
      flex-basis: 30%;
      text-align: center;
    }
  }
  .months {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
    .month {
      &.active {
        background: var(--theme-primary-color);
        border-color: var(--theme-primary-color);
        color: #fff;
      }
      cursor: pointer;
      flex: 1 1 20%;
      padding: 5px;
      border-radius: var(--theme-input-radius);
      background: #fff;
      border: 1px solid #ddd;
      box-sizing: border-box;
      text-align: center;
      &:hover{
        color: #fff;
        color: var(--theme-primary-color);
        border-color: var(--theme-primary-color);
        &.active {
        background: var(--theme-primary-color);
        border-color: var(--theme-primary-color);
        color: #fff;
      }
      }
    }
  }
}
.fake-input {
  position: absolute;
  z-index: -100;
  opacity: 0;
}
.input, .fake-input {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  text-indent: 8px;
  width: 100%;
  height: auto;
  font-family: inherit;
  padding: 4px 2px;
  border: 1px solid #ddd;
  border-radius: var(--theme-input-radius);
  margin: 4px 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus+.wrap-picker, &+.wrap-picker:hover {
    transform: scale(1);
  }
}
.input[type=number] {
  text-align: right;
}
.input[type=number]::-webkit-inner-spin-button,
.input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}
.input:focus, .fake-input:focus+.input {
  border-color: var(--theme-primary-color);
  animation: shadow 0.1s ease-in-out forwards;
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
    /* box-shadow: 0 0 6px red; */
  }
}
.hide-caret {
  caret-color: transparent !important;
}
