
.tab {
  &:last-of-type {
    margin-bottom: 10px;
  }
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius:0 0 5px 5px;
  // overflow: hidden;
  overflow: visible;
  .tab-content{
    flex-grow: 1;
    border-top: 0;
    min-height: 80px;
    box-sizing: border-box;
    // overflow: auto;
    overflow: visible;
  }

  .groups {
    list-style-type: none;
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    .tab-button {
      width:100%;
      min-width:150px;
      font-weight:600;
      border-bottom: 1px solid #e3e2da;
      .tab-label {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        display: block;
        margin:0;
        // background: #f0edeeaa;
        // background: #F6F7F9;

        border-top: 6px solid #e3e2da;
        padding-bottom: 5px;
      }
      .active {
        // background: #fcfcfc77;
        border-top: 6px solid var(--theme-primary-color);
      }
    }
  }

  &.not-stretch {
    border: none;
    .groups {
      overflow: visible;
      border-bottom: 1px solid #e8e8e8;
      .tab-button {
        width: auto;
        min-width: auto;
        border-bottom: 0;
        label {
          position: relative;
          padding: 0 4px;
          height: 30px;
          color: #A0A0A0;
          line-height: 30px;
          border-top: 0;
          background-color: transparent;
          &.active {
            color: #000;
            &:after {
              display: block;
              position: absolute;
              left: 0;
              bottom: -2px;
              width: 100%;
              height: 2px;
              background-color: #000;
              content: "";
            }
          }
        }
        &:nth-child(n+2) {
          margin-left: 20px;
        }
      }
    }
  }
}
.vertical {
  border-radius:0 5px 5px 0;
  .groups .tab-button {
    border-bottom: 0;
    border-right: 1px solid #e3e2da;
    box-sizing: border-box;
    .tab-label {
      // height: 30px;
      // line-height: 30px;
      // border-left: 6px solid #f0edeeaa;
      border-left: 6px solid #e3e2da;
      border-top: 0;
    }
    .active {
      border-top: 0;
      // background: #fcfcfc77;
      border-left: 6px solid var(--theme-primary-color);
    }
  }

}
.flex {
  display: flex;
}

