
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: #0008;
  z-index: 101;
}
.popup-wrap {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.popup-container {
  z-index: 103;
  min-width: 100px;
  width: fit-content;
  height: fit-content;
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 100px);
  // overflow: auto;
  padding: 38px 38px 24px 38px;
  border: 1px solid #cccccc;
  border-radius: var(--theme-input-radius);
  box-shadow: 0 0 20px 0 #999999;
  // background: #fff;
  background: var(--theme-background-color);
  .popup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 6px;
  }
  .description {
    font-size: 15px;
    color: #888899;
    white-space: pre-line;
    // padding-bottom: 10px;
    /deep/.red {
      color:#ff0000;
    }
  }
  .button-group {
    margin-top:10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
