
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  word-wrap: break-word;
}
table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  thead {
    user-select: none;
    th {
      box-sizing: border-box;
      position: sticky;
      // align-self: auto;
      z-index: 1;
      top: 0;
      padding: 5px 10px;
      font-size: 11.5px;
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      // color: #444;
      color: var(--theme-dark-color);
      text-align: initial;
      vertical-align: middle;
      border-bottom: 1px solid #ddd;
      // background-color: var(--theme-background-color);

      backdrop-filter: blur(3px);
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
    }
  }
   tfoot {
    user-select: none;
    th {
      box-sizing: border-box;
      position: sticky;
      z-index: 1;
      top: 0;
      padding: 5px 6px;
      font-size: 11.5px;
      font-size: 14px;
      line-height: 30px;
      font-weight: 600;
      color: #fff;
      text-align: initial;
      vertical-align: middle;
      border-bottom: 1px solid #ddd;
      background-color: rgb(10, 13, 5);
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }
  }
  &::v-deep tbody {
    text-align: initial;
    white-space: nowrap;
    tr {
      &:nth-child(even) {
        // background: #fefefe99;
        // background: #f2f2f2aa;
        // background: #f3f3f333;
      }
      &:hover {
        // background: #efefefee;
        box-shadow: inset 0px -10px 20px #8a8a8a22;

      }
      td {
        border-bottom: 1px solid #E0E0E0;
        line-height: 30px;

        font-weight: 600;
        font-size: 13.5px;
        padding: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        word-wrap: break-word;
        vertical-align: middle;
        &:first-child {
          // text-align: center;
        }
      }
    }
  }
}
/** 컬럼 소팅 */
th.sort-by:before, th.sort-by:after {
  position: absolute;
  border: 6px solid transparent;
  content: "";
  display: block;
  // box-sizing: border-box;
  height:42px;
  left:auto;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 0;
  background: transparent;
  overflow: hidden;
  }
th.sort-by.text-right {
  text-indent: 10px;
}
th.sort-by.text-right:before,
th.sort-by.text-right:after {
  left:5px;
  right: auto;
}
th.sort-by:before { border-bottom-color: #bbb; margin-top: -35px; }
th.sort-by:after { border-top-color: #bbb; margin-top: 21px; }
th.sort-by.desc:before { border-bottom-color: #bbb; }
th.sort-by.desc:after { border-top-color: var(--theme-primary-color); }
th.sort-by.asc:before { border-bottom-color: var(--theme-primary-color); }
th.sort-by.asc:after { border-top-color: #bbb }

@media screen and (max-width: 600px) {
  table {
    border: 0;
    colgroup {
      display:none;
    }
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    background: red;
  }

  table tr {
    display: block;
    border: 1px solid #ddd;
    border-radius: var(--theme-input-radius);
    margin: 10px 0;
    padding : 10px;

  }

  table td {
    display: block;
    text-align: right !important;
    min-height: 30px;
  }
  table td:not([data-label]) {
    display: none;
  }
  table td::before {
    content: attr(data-label);
    margin-right: 10px;
    float: left;
    font-weight: 900;
  }

}
