

.navigation {
  // display: block;
  height: 100%;
  overflow: auto;
  user-select: none;
  padding-bottom: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  box-sizing: border-box;
  background-color: var(--theme-dark-color);
  color: var(--theme-background-color);
  font-weight: 600;
  // transition: width 0.2s cubic-bezier(.5,0,.5,1);
  @media (max-width: 1000px) {
    flex-basis: 100%;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  .active {
    font-weight: 800;
    color: var(--theme-warning-color);
  }
}
.main-navigation {
  margin: 20px 30px 0 30px;
  padding: 0;
  >li {
    font-size: 18px;
    line-height: 20px;
    > a {
      color: var(--theme-secondary-color);
      display: inline-block;
      margin: 20px 0 5px 0;

    }
  }
}
.sub-navigation {
  padding-left: 20px;

  >li {
    font-size: 16px;
    > a {
      display: inline-block;
      color: var(--theme-background-color);
      font-weight: 600;
      margin: 5px 0;
    }
  }
}
.user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;

}
.services {
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;;
  }
  margin: 20px 30px 0 30px;
}
.logo {
  font-family: "Pretendard Variable", Pretendard;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 44.2px;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  text-align: left;
  margin-bottom: 36px;
  padding: 0 30px;
  box-sizing: border-box;
  div {
    text-align: right;
    font-size: 20px;
    color: var(--theme-secondary-color);
  }
}
