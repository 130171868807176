
.file-wrap {
    display: flex;
    .files {
        flex: 1 1 auto;
        width: 100%;
        margin-left: 8px;
        .file-name {
            margin-right: 8px;
        }
    }
}
