
sup {
  display: inline-block;
  top: auto;
  font-size: 0.8rem;
  // margin-left: 0.3rem;
  color: #212121;
  &:before {
    display: block;
    content: '*';
  }
}
